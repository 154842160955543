import React, { useState, useEffect } from "react";
import { shape } from "prop-types";
import { Row, Col, Button, Modal, Table, Skeleton, Popconfirm } from "antd";
import { PlusCircleOutlined, DeleteOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons";

import { getFieldbookQuery } from "@graphql/queries";
import { useDeleteWeeklyWateringLog } from "@graphql/mutations";

import FieldbookWeeklyWateringLogsHistoric from "../FieldbookWeeklyWateringLogsHistoric";

import FieldbookWeeklyWateringLogForm from "./form";

const tableProps = {
  size: "middle",
  pagination: false,
};

function FieldbookWeeklyWateringLog({ fieldbook }) {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);
  const [editableData, setEditableData] = useState();
  const [importHistoric, setImportHistoric] = useState(false);
  const [deleteWeeklyWateringLog] = useDeleteWeeklyWateringLog();
  const data = fieldbook.weeklyWateringLogs;
  const toggleImportHistoric = () => setImportHistoric(!importHistoric);

  const handleEdit = record => {
    setEditableData(record);
    setModalVisible(true);
  };

  const handleDelete = id => {
    deleteWeeklyWateringLog({
      variables: { id },
      refetchQueries: [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbook.id },
        },
      ],
    });
  };
  const columns = [
    {
      dataIndex: "week",
      title: "Semana",
    },
    {
      dataIndex: "mm",
      title: "Registo de Rega (mm)",
    },
    {
      dataIndex: "hours",
      title: "Registo de Rega (horas)",
    },
    {
      align: "center",
      render: (_, record) => !fieldbook.isArchived && (
        <>
          <EditOutlined style={{ marginRight: 10 }} onClick={() => handleEdit(record)} />

          <Popconfirm
            title="Tem a certeza que quer eliminar este registo semanal rega?"
            onConfirm={() => handleDelete(record.id)}
            okText="Sim"
            cancelText="Não"
          >
            <DeleteOutlined />
          </Popconfirm>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!modalVisible) {
      setEditableData();
    }
  }, [modalVisible]);

  return (
    <>
      {!fieldbook.isArchived && (
        <>
          <Modal
            title="Histórico de Registos Semanais Rega"
            width="90%"
            footer={null}
            onCancel={toggleImportHistoric}
            visible={importHistoric}
          >
            <FieldbookWeeklyWateringLogsHistoric
              fieldbookId={fieldbook.id}
              afterSubmit={toggleImportHistoric}
              producersIds={
                fieldbook && fieldbook.producers ? fieldbook.producers.map(({ producer }) => producer.id) : []
              }
            />
          </Modal>

          <Modal
            title={`${editableData ? "Editar" : "Criar"} registo semanal rega`}
            visible={modalVisible}
            onCancel={() => toggleModal()}
            footer={null}
            width={900}
            centered
          >
            <FieldbookWeeklyWateringLogForm
              data={fieldbook}
              afterSubmit={() => {
                toggleModal();
                setEditableData();
              }}
              editableData={editableData}
            />
          </Modal>

          <Row style={{ marginTop: 30 }}>
            <Col md={24} style={{ textAlign: "right" }}>
              <Button icon={<RedoOutlined />} onClick={toggleImportHistoric} style={{ marginRight: 10 }}>
                Importar Histórico
              </Button>

              <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => toggleModal()}>
                Registo Semanal Rega
              </Button>
            </Col>
          </Row>
        </>
      )}

      <br />

      <Skeleton loading={!data} active>
        {data && (
          <Table
            columns={columns}
            dataSource={data}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            pagination={false}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

FieldbookWeeklyWateringLog.propTypes = {
  fieldbook: shape({}).isRequired,
};

export default FieldbookWeeklyWateringLog;
