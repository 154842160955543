import React from "react";
import { Table, TableHeader, TableBody, DataTableCell } from "@david.kucsai/react-pdf-table";
import { arrayOf, shape, func } from "prop-types";

import { cellStyle } from "./style";

function WeeklyWateringLogs({ data, cell: Cell }) {
  return (
    <Table data={data}>
      <TableHeader>
        <Cell>Semana</Cell>
        <Cell>Registo de Rega (mm)</Cell>
        <Cell>Registo de Rega (horas)</Cell>
      </TableHeader>

      <TableBody>
        <DataTableCell style={cellStyle} getContent={({ week }) => week} />
        <DataTableCell style={cellStyle} getContent={({ mm }) => mm} />
        <DataTableCell style={cellStyle} getContent={({ hours }) => hours} />
      </TableBody>
    </Table>
  );
}

WeeklyWateringLogs.propTypes = {
  data: arrayOf(
    shape({}),
  ),
  cell: func.isRequired,
};

WeeklyWateringLogs.defaultProps = {
  data: [],
};

export default WeeklyWateringLogs;
