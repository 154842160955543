/* eslint-disable react/no-array-index-key */
import React from "react";
import { Document, View, Text, Image } from "@react-pdf/renderer";
import { shape, string, node } from "prop-types";
import { Table, TableHeader, TableBody, DataTableCell, TableCell } from "@david.kucsai/react-pdf-table";

import { getGoogleMapsStaticMap } from "@utils/googleMapsUtils";
import { formatDate } from "@utils/dateUtils";
import { groupByN } from "@utils/arrayUtils";
import { getStartDate, calculateNitrogenUnits } from "@helpers/fieldbookHelpers";

import Installations from "./Installations";
import Pesticides from "./Pesticides";
import Fertilizations from "./Fertilizations";
import Waterings from "./Waterings";
import * as S from "./style";
import WeeklyWateringLogs from "./WeeklyWateringLogs";
import FertilizationPlans from "./FertilizationPlans";
// import VisitReports from "./VisitReports";

const goBack = [...Array(3).keys()];
const historyYears = [...goBack.map((index) => new Date().getFullYear() - (index + 1))];
const mapDimensions = {
  w: 800,
  h: 300,
};
const pageProps = {
  size: `A4`,
  orientation: `landscape`,
};
const Cell = ({ children, style }) => <TableCell style={{ ...S.cellHeadStyle, ...style }}>{children}</TableCell>;

Cell.propTypes = {
  style: shape({}),
  children: node.isRequired,
};

Cell.defaultProps = {
  style: {},
};

const labelStyle = { color: "#999", fontSize: 9 };
const valueStyle = { fontSize: 11, marginTop: 5, fontWeight: 900 };
const viewWidth = "33vw";
const viewSpacing = 20;

function FieldbookPdf({ data }) {
  const {
    campaign,
    producers,
    parcels,
    portion,
    installations,
    pesticides,
    fertilizations,
    waterings,
    productionMode,
    certificationSystem,
    operator,
    equipment,
    expert,
    endDate,
    captureType,
    irrigationType,
    pumpingSystem,
    debit,
    water,
    soil,
    pumpPower,
    p2o5,
    k2o,
    coverageCulture,
    coverageCultureStartDate,
    coverageCultureEndDate,
    weeklyWateringLogs,
    fertilizationPlans,
    // visitReports,
  } = data;
  const { coords, polygons, markers, mapZoom } = portion;
  const Footer = () => (
    <S.Footer
      render={() => `
        ${campaign?.culture?.name} ${campaign?.year} - ${portion?.name} - Provape
      `}
      fixed
    />
  );
  const mapUrl =
    coords &&
    getGoogleMapsStaticMap({
      dimensions: `${mapDimensions.w}x${mapDimensions.h}`,
      coords,
      polygons,
      markers,
      mapZoom,
    });
  const Template = ({ title, children }) => (
    <S.Page {...pageProps}>
      <S.Section>
        <S.H3 style={{ marginBottom: 20 }}>{title}</S.H3>

        {children}
      </S.Section>
      <Footer />
    </S.Page>
  );
  const startDate = formatDate(getStartDate(installations));
  const totalWateringVolume = waterings
    .map(({ volume }) => volume)
    .reduce((a, b) => a + b, 0)
    .toFixed(2);
  const totalKgOfN = fertilizationPlans.reduce((acc, { kgOfN }) => acc + kgOfN, 0).toFixed(2);
  const nitrogenUnits = calculateNitrogenUnits({
    water,
    volume: totalWateringVolume,
    efficiency: irrigationType?.efficiency,
  });

  Template.propTypes = {
    title: string.isRequired,
    children: node.isRequired,
  };

  return (
    <Document>
      <S.CoverPage {...pageProps}>
        <S.Section>
          <View style={{ marginLeft: "27vw" }}>
            <Image src="/Provape-Logo.png" style={{ width: "200px", margin: "0 auto 70px" }} />
          </View>

          <S.H1>Caderno de Campo</S.H1>

          <View
            style={{
              marginTop: 50,
              width: "95vw",
              marginRight: "5vw",
              flexDirection: "column",
            }}
          >
            <Table data={producers}>
              <TableHeader>
                <TableCell style={S.cellHeadStyle}>Produtor</TableCell>
                <TableCell style={S.cellHeadStyle}>Número</TableCell>
                <TableCell style={S.cellHeadStyle}>NIF</TableCell>
                <TableCell style={S.cellHeadStyle}>NIFAP</TableCell>
                <TableCell style={S.cellHeadStyle}>Morada</TableCell>
              </TableHeader>

              <TableBody>
                <DataTableCell style={S.cellStyle} getContent={(v) => v.producer?.name} />
                <DataTableCell style={S.cellStyle} getContent={(v) => v.producer?.number} />
                <DataTableCell style={S.cellStyle} getContent={(v) => v.producer?.vatNumber} />
                <DataTableCell style={S.cellStyle} getContent={(v) => v.producer?.nifap} />
                <DataTableCell style={S.cellStyle} getContent={(v) => v.producer?.address} />
              </TableBody>
            </Table>

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                marginTop: viewSpacing,
                textAlign: "left",
              }}
            >
              <View style={{ width: viewWidth, flexDirection: "column" }}>
                <Text style={labelStyle}>Identificação Operador</Text>
                <Text style={valueStyle}>{operator.name}</Text>
              </View>

              <View style={{ width: viewWidth, flexDirection: "column" }}>
                <Text style={labelStyle}>Equipamento Utilizado</Text>
                <Text style={valueStyle}>{equipment.name}</Text>
              </View>

              <View style={{ width: viewWidth, flexDirection: "column" }}>
                <Text style={labelStyle}>Técnico Responsável</Text>
                <Text style={valueStyle}>{expert.name}</Text>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                marginTop: viewSpacing,
                textAlign: "left",
              }}
            >
              <View style={{ width: viewWidth, flexDirection: "column" }}>
                <Text style={labelStyle}>Sistema de Certificação</Text>
                <Text style={valueStyle}>{certificationSystem.name}</Text>
              </View>

              <View style={{ width: viewWidth, flexDirection: "column" }}>
                <Text style={labelStyle}>Cultura</Text>
                <Text style={valueStyle}>{campaign.culture.name}</Text>
              </View>

              <View style={{ width: viewWidth, flexDirection: "column" }}>
                <Text style={labelStyle}>Modo de Produção</Text>
                <Text style={valueStyle}>{productionMode.name}</Text>
              </View>
            </View>
          </View>
        </S.Section>

        <Footer />
      </S.CoverPage>

      <Template title="Identificação da Parcela">
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            marginTop: viewSpacing,
            textAlign: "left",
          }}
        >
          <View style={{ width: "20vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Nome da Parcela</Text>
            <Text style={valueStyle}>{portion.name}</Text>
          </View>

          <View style={{ width: "20vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Localização</Text>
            <Text style={valueStyle}>{portion.location}</Text>
          </View>

          <View style={{ width: "20vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Coordenadas</Text>
            <Text style={{ ...valueStyle, fontSize: 10 }}>
              {coords?.lat ? coords.lat.toFixed(6) : "—"}, {coords?.lng ? coords.lng.toFixed(6) : "—"}
            </Text>
          </View>

          <View style={{ width: "20vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Início da Colheita</Text>
            <Text style={valueStyle}>{startDate}</Text>
          </View>

          <View style={{ width: "20vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Final da Colheita</Text>
            <Text style={valueStyle}>{formatDate(endDate)}</Text>
          </View>
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            marginTop: viewSpacing,
            textAlign: "left",
          }}
        >
          <View style={{ width: "20vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Cultura de cobertura</Text>
            <Text style={valueStyle}>{coverageCulture ?? "—"}</Text>
          </View>

          <View style={{ width: "20vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Data de Instalação</Text>
            <Text style={valueStyle}>{formatDate(coverageCultureStartDate)}</Text>
          </View>

          <View style={{ width: "20vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Data de Colheita</Text>
            <Text style={valueStyle}>{formatDate(coverageCultureEndDate)}</Text>
          </View>
        </View>

        <View style={{ marginTop: 50, width: "95vw", flexDirection: "column" }}>
          <Table data={parcels}>
            <TableHeader>
              <TableCell style={S.cellHeadStyle}>Parcelário</TableCell>
              <TableCell style={S.cellHeadStyle}>Área</TableCell>
              {historyYears.map((key) => (
                <TableCell key={key} style={S.cellHeadStyle}>
                  {key}
                </TableCell>
              ))}
            </TableHeader>

            <TableBody>
              <DataTableCell style={S.cellStyle} getContent={(v) => v.parcel?.number} />
              <DataTableCell style={S.cellStyle} getContent={(v) => v.parcel?.area} />
              <DataTableCell style={S.cellStyle} getContent={(v) => v.parcel?.history?.[0]?.culture?.name} />
              <DataTableCell style={S.cellStyle} getContent={(v) => v.parcel?.history?.[1]?.culture?.name} />
              <DataTableCell style={S.cellStyle} getContent={(v) => v.parcel?.history?.[2]?.culture?.name} />
            </TableBody>
          </Table>
        </View>
      </Template>

      <Template title="Instalações">
        <Installations data={installations} cell={Cell} />
      </Template>

      <Template title="Regas">
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            marginTop: 10,
            marginBottom: viewSpacing,
            textAlign: "left",
          }}
        >
          <View style={{ width: "25vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Origem da Água</Text>
            <Text style={valueStyle}>{captureType.name}</Text>
          </View>

          <View style={{ width: "25vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Tipo de Rega</Text>
            <Text style={valueStyle}>{irrigationType.name}</Text>
          </View>

          <View style={{ width: "25vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Sistema de Bombagem</Text>
            <Text style={valueStyle}>{pumpingSystem.name}</Text>
          </View>

          <View style={{ width: "25vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Potência da Bomba</Text>
            <Text style={valueStyle}>{pumpPower ?? "—"}</Text>
          </View>
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            marginTop: 10,
            marginBottom: viewSpacing,
            textAlign: "left",
          }}
        >
          <View style={{ width: "25vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Eficiência de Rega</Text>
            <Text style={valueStyle}>{irrigationType.efficiency}%</Text>
          </View>

          <View style={{ width: "25vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Débito</Text>
            <Text style={valueStyle}>{debit}</Text>
          </View>

          <View style={{ width: "25vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Total Volume</Text>
            <Text style={valueStyle}>{totalWateringVolume} m3</Text>
          </View>

          <View style={{ width: "25vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Nitratos na Água</Text>
            <Text style={valueStyle}>{water}</Text>
          </View>
        </View>

        <Waterings data={waterings} cell={Cell} />
      </Template>

      <Template title="Fertilizações">
        <Fertilizations
          data={fertilizations}
          soil={parseFloat(soil)}
          nitrogenUnits={parseFloat(nitrogenUnits)}
          cell={Cell}
          p={p2o5}
          k={k2o}
        />
      </Template>

      {groupByN(12, pesticides).map((group) => (
        <Template title="Fitofármacos">
          <Pesticides data={group} cell={Cell} />
        </Template>
      ))}

      <Template title="Registos Semanal Rega">
        <WeeklyWateringLogs data={weeklyWateringLogs} cell={Cell} />
      </Template>

      <Template title="Planos de Fertilização">
        <FertilizationPlans data={fertilizationPlans} cell={Cell} />

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            marginTop: 10,
            marginBottom: viewSpacing,
            textAlign: "left",
          }}
        >
          <View style={{ width: "25vw", flexDirection: "column" }}>
            <Text style={labelStyle}>Total Kg de N</Text>
            <Text style={valueStyle}>{totalKgOfN}</Text>
          </View>
        </View>
      </Template>

      {/* <Template title="Relatórios de Visita">
        <VisitReports data={visitReports} cell={Cell} />
      </Template> */}

      <S.Page {...pageProps}>
        <S.Section>
          <S.H3 style={{ marginBottom: 20 }}>{portion.name}</S.H3>

          {mapUrl ? <S.MapContainer src={mapUrl} /> : <S.H3>Mapa indisponível.</S.H3>}

          <View style={{ marginTop: 400 }}>
            {markers?.map(({ title }, index) => (
              <Text key={index} style={{ fontSize: 10 }}>
                {index + 1} — {title}
              </Text>
            ))}
          </View>
        </S.Section>
        <Footer />
      </S.Page>
    </Document>
  );
}

FieldbookPdf.propTypes = {
  data: shape({
    campaign: shape({
      culture: shape({
        name: string,
      }),
    }),
  }).isRequired,
};

export default FieldbookPdf;
