import React from "react";
import { Table, TableHeader, TableBody, DataTableCell } from "@david.kucsai/react-pdf-table";
import { arrayOf, shape, func } from "prop-types";

import { cellStyle } from "./style";

function FertilizationPlans({ data, cell: Cell }) {
  return (
    <>
      <Table data={data}>
        <TableHeader>
          <Cell>Tipo de Aplicação</Cell>
          <Cell>Unidade</Cell>
          <Cell>Dose</Cell>
          <Cell>Kg de N</Cell>
          <Cell>NPK</Cell>
        </TableHeader>
        <TableBody>
          <DataTableCell style={cellStyle} getContent={({ applicationType }) => applicationType?.name} />
          <DataTableCell style={cellStyle} getContent={({ unity }) => unity?.name} />
          <DataTableCell style={cellStyle} getContent={({ dose }) => dose} />
          <DataTableCell style={cellStyle} getContent={({ kgOfN }) => kgOfN} />
          <DataTableCell style={cellStyle} getContent={({ n, p, k }) => `${n}:${p}:${k}`} />
        </TableBody>
      </Table>
    </>
  );
}

FertilizationPlans.propTypes = {
  data: arrayOf(shape({})),
  cell: func.isRequired,
};

FertilizationPlans.defaultProps = {
  data: [],
};

export default FertilizationPlans;
