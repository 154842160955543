import React from "react";
import { Table, TableHeader, TableBody, DataTableCell } from "@david.kucsai/react-pdf-table";
import { arrayOf, shape, func } from "prop-types";
import { formatDate } from "utils/dateUtils";

import { cellStyle } from "./style";

function Waterings({ data, cell: Cell }) {
  return (
    <Table data={data}>
      <TableHeader>
        <Cell>Início</Cell>
        <Cell>Fim</Cell>
        <Cell>Estado Fenológico</Cell>
        <Cell>Horas de Rega</Cell>
        <Cell>Volume de Água (m3)</Cell>
      </TableHeader>
      <TableBody>
        <DataTableCell style={cellStyle} getContent={({ startDate }) => (startDate ? formatDate(startDate) : "")} />
        <DataTableCell style={cellStyle} getContent={({ endDate }) => (endDate ? formatDate(endDate) : "")} />
        <DataTableCell style={cellStyle} getContent={({ phenologicalState }) => phenologicalState?.name} />
        <DataTableCell style={cellStyle} getContent={({ hours }) => hours} />
        <DataTableCell style={cellStyle} getContent={({ volume }) => volume} />
      </TableBody>
    </Table>
  );
}

Waterings.propTypes = {
  data: arrayOf(
    shape({}),
  ),
  cell: func.isRequired,
};

Waterings.defaultProps = {
  data: [],
};

export default Waterings;
