import React, { useState, useEffect } from "react";
import { Form, Button, Select, Modal, Row, Col, InputNumber, Skeleton, DatePicker } from "antd";
import { func, string, shape } from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  getPortionsQuery,
  getProducersQuery,
  getFieldbooksQuery,
  getIrrigationTypesQuery,
  getCaptureTypesQuery,
  getPortionQuery,
  getPumpingSystemsQuery,
  getProductionModesQuery,
  getCertificationSystemsQuery,
  getExpertsQuery,
  getOperatorsQuery,
  getEquipmentsQuery,
  getFieldbookQuery,
  getCulturesQuery,
} from "@graphql/queries";
import { useCreateFieldbook, useUpdateFieldbook } from "@graphql/mutations";

import ManagePortionForm from "../../ManagePortion/ManagePortionForm";
import ManageProducerForm from "../../ManageProducer/ManageProducerForm";
import ManageExpertForm from "../../ManageExpert/ManageExpertForm";
import ManageOperatorForm from "../../ManageOperator/ManageOperatorForm";
import ManageEquipmentForm from "../../ManageEquipment/ManageEquipmentForm";
import ManagePortionParcels from "../../ManagePortion/ManagePortionParcels";

const { Option } = Select;

function FieldbookForm({ campaignId, afterSubmit, editableData }) {
  const [form] = Form.useForm();
  const [createFieldbook, { loading }] = useCreateFieldbook();
  const [updateFieldbook, { loadingUpdate }] = useUpdateFieldbook();
  const [currentPortion, setCurrentPortion] = useState();
  const [newPortion, setNewPortion] = useState(false);
  const [newParcel, setNewParcel] = useState(false);
  const [newProducer, setNewProducer] = useState(false);
  const [newExpert, setNewExpert] = useState(false);
  const [newOperator, setNewOperator] = useState(false);
  const [newEquipment, setNewEquipment] = useState(false);
  const { loading: loadingCultures, data: culturesData } = useQuery(getCulturesQuery);
  const { loading: loadingPortions, data: portionsData } = useQuery(getPortionsQuery);
  const { loading: loadingProducers, data: producersData } = useQuery(getProducersQuery);
  const { loading: loadingIrrigationTypes, data: irrigationTypesData } = useQuery(getIrrigationTypesQuery);
  const { loading: loadingCaptureTypes, data: captureTypesData } = useQuery(getCaptureTypesQuery);
  const { loading: loadingPumpingSystems, data: pumpingSystemsData } = useQuery(getPumpingSystemsQuery);
  const { loading: loadingProductionModes, data: productionModesData } = useQuery(getProductionModesQuery);
  const { loading: loadingExperts, data: expertsData } = useQuery(getExpertsQuery);
  const { loading: loadingOperators, data: operatorsData } = useQuery(getOperatorsQuery);
  const { loading: loadingEquipments, data: equipmentsData } = useQuery(getEquipmentsQuery);
  const { loading: loadingParcels, data: parcelsData } = useQuery(getPortionQuery, {
    variables: { id: currentPortion },
    skip: !currentPortion,
  });
  const { loading: loadingCertificationSystems, data: certificationSystemsData } =
    useQuery(getCertificationSystemsQuery);
  const setPortion = (e) => setCurrentPortion(e);
  const isLoadingResources = () =>
    loadingPortions ||
    loadingProducers ||
    loadingIrrigationTypes ||
    loadingCaptureTypes ||
    loadingPumpingSystems ||
    loadingProductionModes ||
    loadingCertificationSystems ||
    loadingExperts ||
    loadingOperators ||
    loadingEquipments ||
    loadingParcels;

  useEffect(() => {
    form.resetFields();

    if (editableData) {
      const {
        parcels,
        producers,
        water,
        soil,
        irrigationType,
        captureType,
        pumpingSystem,
        pumpPower,
        debit,
        productionMode,
        certificationSystem,
        expert,
        operator,
        equipment,
        k2o,
        p2o5,
        coverageCulture,
        coverageCultureStartDate,
        coverageCultureEndDate,
      } = editableData;

      form.setFieldsValue({
        portion: parcels[0]?.parcel?.portion?.id,
        parcels: parcels?.map(({ parcel }) => parcel?.id),
        producer: producers?.map(({ producer }) => producer?.id),
        water: parseFloat(water),
        soil: parseFloat(soil),
        irrigationType: irrigationType?.id,
        captureType: captureType?.id,
        pumpingSystem: pumpingSystem?.id,
        debit: parseFloat(debit),
        productionMode: productionMode?.id,
        certificationSystem: certificationSystem?.id,
        expert: expert?.id,
        operator: operator?.id,
        equipment: equipment?.id,
        k2o: parseFloat(k2o),
        p2o5: parseFloat(p2o5),
        coverageCulture,
        coverageCultureStartDate: coverageCultureStartDate ? moment(coverageCultureStartDate) : null,
        coverageCultureEndDate: coverageCultureEndDate ? moment(coverageCultureEndDate) : null,
        pumpPower: parseFloat(pumpPower),
      });

      setPortion(parcels[0]?.parcel?.portion?.id);
    }
  }, [editableData, form]);

  const onFinish = ({
    portion,
    parcels,
    producer,
    water,
    soil,
    irrigationType,
    captureType,
    pumpingSystem,
    productionMode,
    certificationSystem,
    expert,
    operator,
    equipment,
    debit,
    pumpPower,
    coverageCulture,
    coverageCultureStartDate,
    coverageCultureEndDate,
    p2o5,
    k2o,
  }) => {
    const connectProducers = producer.map((id) => ({ id }));
    const mappedProducers = Object.values(connectProducers).map(({ id }) => ({
      producer: {
        connect: { id },
      },
    }));
    const connectParcels = parcels.map((id) => ({ id }));
    const mappedParcels = Object.values(connectParcels).map(({ id }) => ({
      parcel: {
        connect: { id },
      },
    }));
    const mutation = editableData ? updateFieldbook : createFieldbook;
    const refetchQueries = [
      {
        query: getFieldbooksQuery,
        variables: { campaign: campaignId },
      },
      editableData
        ? {
            query: getFieldbookQuery,
            variables: { id: editableData?.id },
          }
        : {},
    ].filter(Boolean);

    if (editableData) {
      updateFieldbook({
        variables: {
          id: editableData?.id,
          data: {
            producers: {
              deleteMany: {
                producerId: {
                  notIn: ["0"],
                },
              },
            },
            parcels: {
              deleteMany: {
                parcelId: {
                  notIn: ["0"],
                },
              },
            },
          },
        },
      });
    }

    mutation({
      variables: {
        ...(editableData && { id: editableData.id }),
        data: {
          irrigationType: {
            connect: { id: irrigationType?.id ?? irrigationType },
          },
          captureType: {
            connect: { id: captureType?.id ?? captureType },
          },
          pumpingSystem: {
            connect: { id: pumpingSystem?.id ?? pumpingSystem },
          },
          productionMode: {
            connect: { id: productionMode?.id ?? productionMode },
          },
          certificationSystem: {
            connect: { id: certificationSystem?.id ?? certificationSystem },
          },
          expert: {
            connect: { id: expert?.id ?? expert },
          },
          operator: {
            connect: { id: operator?.id ?? operator },
          },
          equipment: {
            connect: { id: equipment?.id ?? equipment },
          },
          water: editableData ? { set: Number(water) } : Number(water),
          soil: editableData ? { set: Number(soil) } : Number(soil),
          campaign: {
            connect: { id: campaignId },
          },
          portion: {
            connect: { id: portion },
          },
          producers: {
            create: [...Object.values(mappedProducers)],
          },
          parcels: {
            create: [...Object.values(mappedParcels)],
          },
          debit: editableData ? { set: Number(debit) } : Number(debit),
          pumpPower: editableData ? { set: String(pumpPower) } : String(pumpPower),
          coverageCulture: editableData ? { set: coverageCulture } : coverageCulture,
          p2o5: editableData ? { set: Number(p2o5) } : Number(p2o5),
          k2o: editableData ? { set: Number(k2o) } : Number(k2o),
          coverageCultureStartDate: editableData ? { set: coverageCultureStartDate } : coverageCultureStartDate,
          coverageCultureEndDate: editableData ? { set: coverageCultureEndDate } : coverageCultureEndDate,
        },
      },
      refetchQueries,
    }).then(() => afterSubmit());
  };
  const getPortion = () => form.getFieldValue("portion");

  if (isLoadingResources()) return <Skeleton />;

  return (
    <Form onFinish={onFinish} form={form}>
      <Row>
        <Col span={24}>
          <Button
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => setNewPortion(!newPortion)}
            style={{ marginRight: 10 }}
          >
            Parcela
          </Button>

          <Button
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => setNewParcel(!newParcel)}
            style={{ marginRight: 10 }}
            disabled={!getPortion()}
          >
            Parcelário
          </Button>

          <Button
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => setNewProducer(!newProducer)}
            style={{ marginRight: 10 }}
          >
            Produtor
          </Button>

          <Button
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => setNewExpert(!newExpert)}
            style={{ marginRight: 10 }}
          >
            Técnico
          </Button>

          <Button
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => setNewOperator(!newOperator)}
            style={{ marginRight: 10 }}
          >
            Operador
          </Button>

          <Button
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => setNewEquipment(!newEquipment)}
            style={{ marginRight: 10 }}
          >
            Equipamento
          </Button>
        </Col>
      </Row>

      <Modal title="Criar nova parcela" visible={newPortion} onCancel={() => setNewPortion(!newPortion)} footer={null}>
        <ManagePortionForm afterSubmit={() => setNewPortion(!newPortion)} />
      </Modal>

      <Modal
        title="Criar novo produtor"
        visible={newProducer}
        onCancel={() => setNewProducer(!newProducer)}
        footer={null}
      >
        <ManageProducerForm afterSubmit={() => setNewProducer(!newProducer)} />
      </Modal>

      <Modal title="Criar novo técnico" visible={newExpert} onCancel={() => setNewExpert(!newExpert)} footer={null}>
        <ManageExpertForm afterSubmit={() => setNewExpert(!newExpert)} />
      </Modal>

      <Modal
        title="Criar novo operador"
        visible={newOperator}
        onCancel={() => setNewOperator(!newOperator)}
        footer={null}
      >
        <ManageOperatorForm afterSubmit={() => setNewOperator(!newOperator)} />
      </Modal>

      <Modal
        title="Criar novo equipamento"
        visible={newEquipment}
        onCancel={() => setNewEquipment(!newEquipment)}
        footer={null}
      >
        <ManageEquipmentForm afterSubmit={() => setNewEquipment(!newEquipment)} />
      </Modal>

      {getPortion() && (
        <Modal
          width={960}
          title="Gestão de parcelários"
          visible={newParcel}
          onCancel={() => setNewParcel(!newParcel)}
          onOk={() => setNewParcel(!newParcel)}
          cancelText={false}
        >
          <ManagePortionParcels portionId={getPortion()} data={parcelsData?.portion?.parcels} />
        </Modal>
      )}

      <br />

      <Form.Item
        name="portion"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Select
          loading={loadingPortions}
          placeholder="Escolha uma parcela"
          size="large"
          optionFilterProp="children"
          onChange={setPortion}
          showSearch
        >
          {portionsData?.portions.map(({ id, name }) => (
            <Option value={id} key={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {currentPortion && (
        <Form.Item
          name="parcels"
          rules={[
            {
              required: true,
              message: `Campo obrigatório`,
            },
          ]}
          hasFeedback
        >
          <Select
            loading={loadingParcels}
            placeholder="Escolha parcelários"
            size="large"
            optionFilterProp="children"
            mode="multiple"
            showSearch
          >
            {parcelsData?.portion?.parcels.map(({ id, number }) => (
              <Option value={id} key={id}>
                {number}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="producer"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingProducers}
              placeholder="Escolha um produtor"
              size="large"
              optionFilterProp="children"
              mode="multiple"
              showSearch
            >
              {producersData &&
                producersData.producers.map(({ id, name }) => (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="water">
            <InputNumber placeholder="N da Água" size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="soil"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <InputNumber placeholder="N do Solo" size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="irrigationType"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingIrrigationTypes}
              placeholder="Tipo de rega"
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {irrigationTypesData?.irrigationTypes.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="captureType"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingCaptureTypes}
              placeholder="Tipo de captação"
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {captureTypesData?.captureTypes.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="pumpingSystem"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingPumpingSystems}
              placeholder="Sistema de Bombagem"
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {pumpingSystemsData?.pumpingSystems.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="pumpPower"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <InputNumber placeholder="Potência da Bomba" size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="debit"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <InputNumber placeholder="Débito" size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="productionMode"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingProductionModes}
              placeholder="Modo de Produção"
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {productionModesData?.productionModes.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="certificationSystem"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingCertificationSystems}
              placeholder="Sistema de Certificação"
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {certificationSystemsData?.certificationSystems.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="expert"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select loading={loadingExperts} placeholder="Técnico" size="large" optionFilterProp="children" showSearch>
              {expertsData?.experts.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="operator"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingOperators}
              placeholder="Operador"
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {operatorsData?.operators.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="equipment"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingEquipments}
              placeholder="Equipamento"
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {equipmentsData?.findManyEquipment?.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="p2o5"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <InputNumber placeholder="P2O5" size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="k2o"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <InputNumber placeholder="K2O" size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <div style={{ fontWeight: 600 }}>Cultura de cobertura</div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="coverageCulture"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select loading={loadingCultures} placeholder="Cultura" size="large" optionFilterProp="children" showSearch>
              {culturesData?.cultures?.map(({ id, name }) => (
                <Option value={name} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="coverageCultureStartDate"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <DatePicker placeholder="Data de instalação" size="large" style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="coverageCultureEndDate"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <DatePicker placeholder="Data de colheita" size="large" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Row align="center">
        <Col span={8}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={loading || loadingUpdate}
            loading={loading || loadingUpdate}
            block
          >
            {editableData ? "Actualizar" : "Criar"} caderno de campo
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

FieldbookForm.propTypes = {
  campaignId: string.isRequired,
  afterSubmit: func,
  editableData: shape({}),
};

FieldbookForm.defaultProps = {
  afterSubmit: null,
  editableData: null,
};

export default FieldbookForm;
