import React, { useState, useEffect } from "react";
import { shape } from "prop-types";
import { Row, Col, Button, Modal, Table, Skeleton, Popconfirm } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
} from "@ant-design/icons";

import { formatDate } from "@utils/dateUtils";
import { getFieldbookQuery } from "@graphql/queries";
import { useDeleteVisitReport } from "@graphql/mutations";

import FieldbookVisitReportsHistoric from "../FieldbookVisitReportsHistoric";

import Form from "./Form";

const tableProps = {
  size: "middle",
  pagination: false,
};

function Index({ fieldbook }) {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);
  const [editableData, setEditableData] = useState();
  const [importHistoric, setImportHistoric] = useState(false);
  const [deleteVisitReport] = useDeleteVisitReport();
  const data = fieldbook.visitReports;
  const toggleImportHistoric = () => setImportHistoric(!importHistoric);

  const handleEdit = record => {
    setEditableData(record);
    setModalVisible(true);
  };

  const handleDelete = id => {
    deleteVisitReport({
      variables: { id },
      refetchQueries: [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbook.id },
        },
      ],
    });
  };
  const columns = [
    {
      dataIndex: "date",
      title: "Data",
      render: formatDate,
    },
    {
      dataIndex: "intervention",
      title: "Intervenção / Doença / Praga / Infestante detectada acima do NEA",
    },
    {
      dataIndex: "recommendedActiveSubstance",
      title: "Substância Ativa Recomendada",
    },
    {
      align: "center",
      render: (_, record) => !fieldbook.isArchived && (
        <>
          <EditOutlined
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          />

          <Popconfirm
            title="Tem a certeza que quer eliminar este relatório de visita?"
            onConfirm={() => handleDelete(record.id)}
            okText="Sim"
            cancelText="Não"
          >
            <DeleteOutlined />
          </Popconfirm>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!modalVisible) {
      setEditableData();
    }
  }, [modalVisible]);

  return (
    <>
      {!fieldbook.isArchived && (
        <>
          <Modal
            title="Histórico de Relatórios de Visita"
            width="90%"
            footer={null}
            onCancel={toggleImportHistoric}
            visible={importHistoric}
          >
            <FieldbookVisitReportsHistoric
              fieldbookId={fieldbook.id}
              afterSubmit={toggleImportHistoric}
              producersIds={
                fieldbook && fieldbook.producers ? fieldbook.producers.map(({ producer }) => producer.id) : []
              }
            />
          </Modal>

          <Modal
            title={`${editableData ? "Editar" : "Criar"} relatório de visita`}
            visible={modalVisible}
            onCancel={() => toggleModal()}
            footer={null}
            width={900}
            centered
          >
            <Form
              data={fieldbook}
              afterSubmit={() => {
                toggleModal();
                setEditableData();
              }}
              editableData={editableData}
            />
          </Modal>

          <Row style={{ marginTop: 30 }}>
            <Col md={24} style={{ textAlign: "right" }}>
              <Button icon={<RedoOutlined />} onClick={toggleImportHistoric} style={{ marginRight: 10 }}>
                Importar Histórico
              </Button>

              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => toggleModal()}
              >
                Relatório de visita
              </Button>
            </Col>
          </Row>
        </>
      )}

      <br />

      <Skeleton loading={!data} active>
        {data && (
          <Table
            columns={columns}
            dataSource={data}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            pagination={false}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

Index.propTypes = {
  fieldbook: shape({}).isRequired,
};

export default Index;
