import React, { useState } from "react";
import { Card, Row, Col, Statistic, Tooltip, DatePicker, Button } from "antd";
import { CompressOutlined, EditOutlined, ExpandOutlined } from "@ant-design/icons";
import { shape } from "prop-types";
import moment from "moment";

import { formatDate } from "@utils/dateUtils";
import { getFieldbookQuery } from "@graphql/queries";
import { useUpdateFieldbook } from "@graphql/mutations";
import { notificationSuccess, mutationTypes } from "@utils/graphqlUtils";
import { getStartDate } from "@helpers/fieldbookHelpers";

const valuesStyle = {
  fontWeight: 600,
  fontSize: 18,
};
const UPDATED_MESSAGE = "Caderno de campo actualizado";

function FieldbookDetails({ fieldbook }) {
  const [editEndDate, setEditEndDate] = useState(false);
  const [compressed, setCompressed] = useState(false);
  const [updateFieldbook, { loading }] = useUpdateFieldbook();
  const toggleEndDateEdit = () => setEditEndDate(!editEndDate);

  const update = (field, value) => {
    updateFieldbook({
      variables: {
        id: fieldbook.id,
        data: {
          [field]: { set: value },
        },
      },
      refetchQueries: [
        {
          query: getFieldbookQuery,
          variables: {
            id: fieldbook.id,
          },
        },
      ],
    }).then(() => {
      notificationSuccess(UPDATED_MESSAGE, mutationTypes.UPDATE);
      setEditEndDate(false);
    });
  };
  const updateEndDate = (field) => update(`endDate`, field);

  return (
    <Card>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button onClick={() => setCompressed(!compressed)} style={{ marginBottom: 15 }}>
          {compressed ? <ExpandOutlined /> : <CompressOutlined />}
        </Button>
      </div>

      <Row>
        <Col span={6}>
          <Statistic
            title="Produtores"
            value={fieldbook}
            formatter={(data) => {
              const producers = data?.producers;

              return (
                <>
                  <Tooltip
                    placement="bottom"
                    title={producers
                      .slice(Math.max(producers.length - producers.length, 1))
                      .map(({ producer: { id, name } }) => (
                        <div key={id}>{name}</div>
                      ))}
                  >
                    {producers[0]?.producer.name}{" "}
                    {producers.length > 1 && <span style={{ fontSize: 14 }}>+{producers.length - 1}</span>}
                  </Tooltip>
                </>
              );
            }}
            valueStyle={valuesStyle}
          />
        </Col>

        <Col span={6}>
          <Statistic title="Cultura" value={fieldbook?.campaign.culture.name} valueStyle={valuesStyle} />
        </Col>

        <Col span={6}>
          <Statistic
            title="Ano"
            value={fieldbook?.campaign.year}
            formatter={(value) => value}
            valueStyle={valuesStyle}
          />
        </Col>

        <Col span={6}>
          <Statistic
            title="Parcelários"
            value={fieldbook}
            formatter={(data) => {
              const parcels = data?.parcels;

              return (
                <>
                  <Tooltip
                    placement="bottom"
                    title={parcels?.slice(Math.max(parcels.length - parcels.length, 1))?.map(({ parcel }) => (
                      <div key={parcel?.id}>{parcel?.number}</div>
                    ))}
                  >
                    {parcels[0]?.parcel?.number}{" "}
                    {parcels.length > 1 && <span style={{ fontSize: 14 }}>+{parcels.length - 1}</span>}
                  </Tooltip>
                </>
              );
            }}
            valueStyle={valuesStyle}
          />
        </Col>
      </Row>

      {compressed ? null : (
        <>
          <br />

          <Row>
            <Col span={6}>
              <Statistic
                title="Área Total"
                value={fieldbook}
                formatter={(data) => {
                  const parcels = data?.parcels;

                  return parcels
                    .filter(({ parcel }) => parcel)
                    ?.map(({ parcel }) => parcel?.area)
                    ?.reduce((a, b) => a + b, 0)
                    .toFixed(2);
                }}
                valueStyle={valuesStyle}
                suffix=" ha"
              />
            </Col>

            <Col span={6}>
              <Statistic title="Valor Água" value={fieldbook?.water} valueStyle={valuesStyle} suffix="g" />
            </Col>

            <Col span={6}>
              <Statistic
                title="Valor Solo"
                value={fieldbook?.soil}
                formatter={(value) => value}
                valueStyle={valuesStyle}
                suffix="g"
              />
            </Col>

            <Col span={6}>
              <Statistic
                title="Tipo de Rega"
                value={fieldbook?.irrigationType?.name}
                formatter={(value) => value}
                suffix={`/ ${fieldbook?.irrigationType?.efficiency}%`}
                valueStyle={valuesStyle}
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col span={6}>
              <Statistic
                title="Tipo de Captação"
                value={fieldbook?.captureType?.name}
                formatter={(value) => value}
                valueStyle={valuesStyle}
              />
            </Col>

            <Col span={6}>
              <Statistic
                title="Sistema de Bombagem"
                value={fieldbook?.pumpingSystem?.name}
                formatter={(value) => value}
                valueStyle={valuesStyle}
              />
            </Col>

            <Col span={6}>
              <Statistic
                title="Potência da Bomba"
                value={fieldbook?.pumpPower}
                formatter={(value) => value}
                valueStyle={valuesStyle}
              />
            </Col>

            <Col span={6}>
              <Statistic
                title="Débito"
                value={fieldbook?.debit}
                formatter={(value) => value}
                valueStyle={valuesStyle}
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col span={6}>
              <Statistic
                title="Sistema de Certificação"
                value={fieldbook?.certificationSystem?.name}
                formatter={(value) => value}
                valueStyle={valuesStyle}
              />
            </Col>

            <Col span={6}>
              <Statistic
                title="Técnico"
                value={fieldbook?.expert?.name}
                formatter={(value) => value}
                valueStyle={valuesStyle}
              />
            </Col>

            <Col span={6}>
              <Statistic
                title="Operador"
                value={fieldbook?.operator?.name}
                formatter={(value) => value}
                valueStyle={valuesStyle}
              />
            </Col>

            <Col span={6}>
              <Statistic
                title="Equipamento"
                value={fieldbook?.equipment?.name}
                formatter={(value) => value}
                valueStyle={valuesStyle}
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col span={6}>
              <Statistic title="P2O5" value={fieldbook?.p2o5} formatter={(value) => value} valueStyle={valuesStyle} />
            </Col>

            <Col span={6}>
              <Statistic title="K2O" value={fieldbook?.k2o} formatter={(value) => value} valueStyle={valuesStyle} />
            </Col>
          </Row>

          <div style={{ borderTop: "1px solid #eee", margin: "15px 0" }} />

          <Row>
            <Col span={6}>
              <Statistic
                title="Cultura de Cobertura"
                value={fieldbook?.coverageCulture}
                formatter={(value) => value}
                valueStyle={valuesStyle}
              />
            </Col>

            <Col span={6}>
              <Statistic
                title={
                  <>
                    Data de Instalação <span style={{ fontStyle: "italic" }}>(Cultura de Cobertura)</span>
                  </>
                }
                value={fieldbook?.coverageCultureStartDate}
                formatter={(value) => (value ? formatDate(value) : "-")}
                valueStyle={valuesStyle}
              />
            </Col>

            <Col span={6}>
              <Statistic
                title={
                  <>
                    Data de Colheita <span style={{ fontStyle: "italic" }}>(Cultura de Cobertura)</span>
                  </>
                }
                value={fieldbook?.coverageCultureEndDate}
                formatter={(value) => (value ? formatDate(value) : "-")}
                valueStyle={valuesStyle}
              />
            </Col>
          </Row>

          <div style={{ borderTop: "1px solid #eee", margin: "15px 0" }} />

          <Row>
            <Col span={6}>
              <Statistic
                title="Início Colheita"
                value={getStartDate(fieldbook?.installations)}
                formatter={(value) => (value ? formatDate(value) : "-")}
                valueStyle={valuesStyle}
              />
            </Col>

            <Col span={6}>
              <Statistic
                title="Final Colheita"
                value={fieldbook?.endDate}
                formatter={(value) =>
                  !editEndDate ? (
                    <>
                      {value ? formatDate(value) : "-"}
                      <EditOutlined onClick={toggleEndDateEdit} style={{ marginLeft: 10 }} />
                    </>
                  ) : (
                    <DatePicker
                      onChange={updateEndDate}
                      defaultValue={value ? moment(value) : null}
                      disabled={loading}
                    />
                  )
                }
                valueStyle={valuesStyle}
              />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
}

FieldbookDetails.propTypes = {
  fieldbook: shape({}).isRequired,
};

export default FieldbookDetails;
